import { ofetch } from 'ofetch'

export default defineNuxtPlugin((_nuxtApp) => {
  const store = useAuthStore()
  const { getAccessToken } = storeToRefs(store)

  globalThis.$fetch = ofetch.create({
    onRequest({ request, options }) {
      options.headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }
    },
  })
})
