import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-upload": () => import("/app/middleware/auth-upload.js"),
  auth: () => import("/app/middleware/auth.js"),
  login: () => import("/app/middleware/login.js"),
  user: () => import("/app/middleware/user.js")
}