import { createGtm } from '@gtm-support/vue-gtm'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig().public

  if (runtimeConfig.gtmID) {
    nuxtApp.vueApp.use(
      createGtm({
        id: runtimeConfig.gtmID,
        defer: false,
        compatibility: false,
        enabled: true,
        debug: false,
        loadScript: true,
        vueRouter: useRouter(),
        trackOnNextTick: false,
      }),
    )
  }
})
